<template>
  <div id="vm-cola-payment-failed" class="vm-cola-payment-failed">
    <!-- <HomeBanners type="primary" /> -->
    <div class="payment-failed-container">
      <h1 class="title">Payment Failed</h1>
      <!-- <table class="detail" style="display: none;">
        <tr>
          <td>
            <div class="label">Nomor Pesanan</div>
            <div class="value">{{ reference_code }}</div>
          </td>
          <td>
            <div class="label">Total Pesanan</div>
            <div class="value">{{ total_price | currencyRp }}</div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="label">Tanggal Pembayaran</div>
            <div class="value">{{ order_detail.created_at | dateFormat }}</div>
          </td>
          <td>
            <div class="label">Metode Pembayaran</div>
            <div class="value">{{ payment_method }}</div>
          </td>
        </tr>
      </table> -->
      <!-- <h3>ambil belanjaanmu sekarang!</h3> -->
      <h4>
        Maaf, terjadi kendala pada proses pembayaran kamu.
        <br />
        Silakan coba lagi atau kontak Customer Support Team <b>cs@sociolla.com</b>
      </h4>
      <h4><b>Silakan scan QR code berikut:</b></h4>
      <div class="qr-payment-success">
        <div class="qrcode">
          <template v-if="Object.keys(order_detail).length">
            <qrcode-vue :value="QRcodeValue" :size="230" level="H"></qrcode-vue>
          </template>
        </div>
        <!-- <img src="/static/img/icons/qrcode-customer-service.png" alt="" /> -->
      </div>

      <div class="user__action back-to-cart">
        <button @click.prevent="backToCart">
          <span>Back to Cart</span>
        </button>
      </div>

      <div class="divider" />

      <div class="user">
        <div v-if="user" class="user__detail">
          <figure v-if="user && user.image">
            <img :src="user.image" :alt="user.name" />
          </figure>
          <span>Masuk sebagai</span>
          <h4 v-if="user && user.name" class="name">{{ user.name }}</h4>
        </div>
        <div class="user__action">
          <button class="cancel" @click.prevent="cancelOrder(true)">
            <span>Ke Halaman Utama</span>
          </button>
        </div>
      </div>
    </div>

    <div class="banners">
      <HomeBanners type="secondary" />
    </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue';
import helpers from '@/mixins/helpers';
import ls from '@/components/helpers/local-storage';
import autotimeout from '@/mixins/auto-timeout';

const Config = require('~/config/default.env').default;

export default {
  name: 'vmColaPaymentFailed',
  components: {
    HomeBanners: () => import('@/components/vm-cola/snippets/home-banners/home-banners'),
    QrcodeVue,
  },
  mixins: [helpers, autotimeout],

  beforeRouteLeave(to, from, next) {
    if (from.name == 'vmColaPaymentSuccess') {
      ls.remove('PRODUCT_LIST');
      ls.remove('SLOT_PRODUCT');
    }
    this.cancelOrder(true);
    next();
  },
  data() {
    return {
      order_detail: {},
      QRcodeValue: '',
    };
  },
  computed: {
    user() {
      return this.$store.state.QRcode.user;
    },
    isLogin() {
      return this.$store.state.QRcode.isLoggedIn;
    },
    reference_code() {
      return this.$route.query.reference_code;
    },
    total_price() {
      return this.$route.query.total;
    },
    payment_method() {
      return this.$route.query.payment_method;
    },
  },
  mounted() {
    this.getOrderDetail();
    if (!this.$route.query['disable-bucket']) {
      this.getBucket();
    }
  },
  beforeDestroy() {
    this.$store.commit('SET_GLOBAL_LOADING', false);
    this.$store.commit('point_applied', 0);
    this.$store.commit('PRODUCT_LIST', []);
  },
  methods: {
    async backToCart() {
      const is_guest = await localStorage.getItem('is_guest');

      this.$store.commit('SET_GLOBAL_LOADING', true);
      this.$store.commit('SET_ALREADY_SEND_CART', false);

      this.$store
        .dispatch('sendCartData', {
          redirect: '/vmcola/order',
          data: this,
          is_vmcola: true,
          is_guest: is_guest === 'true',
        })
        .then((res) => {
          setTimeout(() => {
            this.$store.commit('SET_GLOBAL_LOADING', false);
          }, 1500);
        })
        .then(() => {
          this.$router.push('/vmcola/order');
        })
        .catch((err) => {
          console.log('Error => ', err);
        });
    },
    async getOrderDetail() {
      const loc = Cookies.get('store_location_id');
      const token = Cookies.get('token');
      const id_order = this.$route.query.id_order;
      if (loc && id_order) {
        const headers = {
          'SOC-PLATFORM': 'vending-machine',
          store_id: loc,
        };
        if (token) {
          headers['Authorization'] = `Bearer ${token}`;
        }

        const is_guest = await ls.get('is_guest');

        if (is_guest) {
          headers['is-guest'] = true;
          headers['guest-checkout'] = true;
        }

        await axios
          .get(`${Config.MS_ORDER_API_URL}/order/${id_order}`, {
            headers: headers,
          })
          .then((res) => {
            if (res.data.data) {
              this.order_detail = res.data.data;

              const text =
                'https://wa.me/811987881?text=Hi%20saya%20mengalami%20kendala%20untuk%20order%20' +
                this.store_detail?.name +
                '%20dengan%20nomor%20transaksi%20' +
                this.order_detail.reference_code;
              this.QRcodeValue = text;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    async getBucket() {
      const loc = Cookies.get('store_location_id');
      const token = Cookies.get('token');
      if (loc) {
        let products = [];
        const headers = {
          'SOC-PLATFORM': 'vending-machine',
          store_id: loc,
        };
        if (token) {
          headers['Authorization'] = `Bearer ${token}`;
        }
        if (ls.get('SLOT_PRODUCT')) {
          products = JSON.parse(ls.get('SLOT_PRODUCT'));
        }
        await axios
          .post(
            `http://localhost:1945/bucket`,
            {
              products: products,
            },
            {
              headers: headers,
            }
          )
          .then((res) => {
            if (res.status && res.status != 200) {
              this.$router.push({ path: '/vmcola/order/payment-success-failed' });
            }
          })
          .catch((err) => {
            this.$router.push({ path: '/vmcola/order/payment-success-failed' });
            console.log(err);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'payment-failed';
</style>
